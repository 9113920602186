/**
 * 国际化配置
 */
import { createI18n } from 'vue-i18n/index'

import zhCNLocale from './zh_CN'

const messages = {
  zh_CN: zhCNLocale
}

const i18n = createI18n({
  messages: messages,
  silentTranslationWarn: true,
  // 默认语言
  locale: localStorage.getItem('i18n-lang') || 'zh_CN'
})

export default i18n
