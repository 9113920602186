<!-- 页脚 -->
<template>
  <div class="ud-text-center" style="padding: 16px 0">
    <a-space size="large"></a-space>
    <div class="ud-text-secondary" style="margin-top: 8px">
      {{ $t('layout.footer.copyright') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UdFooter'
}
</script>
