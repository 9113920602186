/** 带遮罩层loading */
import { message } from 'ant-design-vue'

/**
 * 全局带遮罩层loading
 * @param content
 * @param duration
 * @param onClose
 */
export default function (content, duration, onClose) {
  const loadingClass = 'ud-message-loading'
  const loadingMaskClass = 'ud-message-loading-mask'
  const callback = function (close) {
    return function () {
      const loadingNum = document.body.dataset.loadingNum
      if (!loadingNum || loadingNum === '1') {
        document.body.dataset.loadingNum = '0'
        document.body.classList.remove(loadingClass, loadingMaskClass)
      } else {
        document.body.dataset.loadingNum = String(Number(loadingNum) - 1)
      }
      close && close()
    }
  }
  if (typeof content === 'object') {
    content.onClose = callback(content.onClose)
    if (content.mask === true) {
      document.body.classList.remove(loadingClass)
      document.body.classList.add(loadingMaskClass)
    }
  } else if (!document.body.classList.contains(loadingMaskClass)) {
    document.body.classList.add(loadingClass)
  }
  const loadingNum = document.body.dataset.loadingNum
  document.body.dataset.loadingNum = String((loadingNum ? Number(loadingNum) : 0) + 1)
  const arg2 = typeof duration === 'function' ? callback(duration) : duration
  return message.loading(content, arg2, callback(onClose))
}
