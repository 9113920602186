export default {
  methods: {
    strToArr(str) {
      if (str) {
        return str.split(',')
      } else {
        return []
      }
    },
    arrToStr(arr) {
      if (arr.length > 0) {
        return arr.join(',')
      } else {
        return ''
      }
    },
    getCorpId() {
      return this.$route.query.corpId || 0
    }
  }
}
