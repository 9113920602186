<!-- 顶栏右侧区域按钮 -->
<template>
  <div class="ud-admin-header-tool">
    <div class="ud-admin-header-tool-item hidden-sm-and-down" @click="changeFullscreen">
      <fullscreen-exit-outlined v-if="fullscreen"/>
      <fullscreen-outlined v-else/>
    </div>
    <!-- 消息通知 -->
    <div class="ud-admin-header-tool-item">
      <ud-notice/>
    </div>
    <!-- 用户信息 -->
    <div class="ud-admin-header-tool-item">
      <a-dropdown placement="bottomCenter" :overlay-style="{minWidth: '120px'}">
        <div class="ud-admin-header-avatar">
          <a-avatar :src="loginUser.avatar"/>
          <span>{{ loginUser.nickname }}&nbsp;</span>
          <down-outlined/>
        </div>
        <template #overlay>
          <a-menu @click="onUserDropClick">
            <a-menu-item key="profile">
              <div class="ud-cell">
                <user-outlined/>
                <div class="ud-cell-content">{{ $t('layout.header.profile') }}</div>
              </div>
            </a-menu-item>
            <a-menu-item key="password">
              <div class="ud-cell">
                <key-outlined/>
                <div class="ud-cell-content">{{ $t('layout.header.password') }}</div>
              </div>
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item key="logout">
              <div class="ud-cell">
                <logout-outlined/>
                <div class="ud-cell-content">{{ $t('layout.header.logout') }}</div>
              </div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <!-- 主题设置 -->
    <div v-if="showSetting" class="ud-admin-header-tool-item" @click="openSetting">
      <MoreOutlined/>
    </div>
  </div>
</template>

<script>
import { createVNode } from 'vue'
import {
  DownOutlined,
  MoreOutlined,
  UserOutlined,
  KeyOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined
} from '@ant-design/icons-vue'
import { toggleFullscreen, isFullscreen } from '@/utils/util.js'
import UdNotice from './notice'

export default {
  name: 'UdHeaderRight',
  components: {
    DownOutlined,
    MoreOutlined,
    UserOutlined,
    KeyOutlined,
    LogoutOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    UdNotice
  },
  emits: ['item-click', 'change-language'],
  props: {
    // 是否显示打开设置抽屉按钮
    showSetting: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // 当前登录用户信息
    loginUser() {
      return this.$store.state.user.user
    }
  },
  data() {
    return {
      // 是否全屏状态
      fullscreen: false
    }
  },
  methods: {
    /* 个人信息下拉菜单点击 */
    onUserDropClick({ key }) {
      if (key === 'logout') {
        // 退出登录
        this.$confirm({
          title: this.$t('layout.logout.title'),
          content: this.$t('layout.logout.message'),
          icon: createVNode(ExclamationCircleOutlined),
          maskClosable: true,
          onOk: () => {
            // 清除缓存的token
            this.$store.dispatch('user/removeToken').then(() => {
              location.replace('/login') // 这样跳转避免再次登录重复注册动态路由
            })
          }
        })
      } else if (key === 'profile') {
        this.$router.push('/user/profile')
      } else if (key === 'password') {
        this.$emit('item-click', 'password')
      }
    },
    /* 打开设置抽屉 */
    openSetting() {
      this.$emit('item-click', 'setting')
    },
    /* 全屏切换 */
    changeFullscreen() {
      try {
        this.fullscreen = toggleFullscreen()
      } catch (e) {
        this.$message.error('您的浏览器不支持全屏模式')
      }
    },
    /* 检查全屏状态 */
    checkFullscreen() {
      this.fullscreen = isFullscreen()
    }
  }
}
</script>
