/** 主入口js */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './config/axios-config'
import permission from './utils/permission'
import './styles/index.less'
import UdAdmin from 'ud-admin-vue'
import i18n from './lang'
import mixin from '@/common/mixin'
import ModalUtil from '@/utils/modal-util'
import messageLoading from '@/utils/message-loading.js'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
const app = createApp(App)
app.config.productionTip = false
app.config.globalProperties.$messageLoading = messageLoading
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(permission)
app.use(UdAdmin)
app.use(ModalUtil)
app.use(i18n)
app.mixin(mixin)
app.mount('#app')
