import { generate } from '@ant-design/colors'
import { TinyColor } from '@ctrl/tinycolor'

/**
 * 切换主题色
 * @param color 颜色值
 * @param dark 是否暗黑模式
 */
export function changeColor(color, dark) {
  const id = 'ud-admin-theme-var'
  const elem = document.getElementById(id)
  if (elem && elem.parentNode) {
    elem.parentNode.removeChild(elem)
  }
  if (color) {
    const colors = generate(color, dark ? { theme: 'dark' } : {})
    const rgb = new TinyColor(color).toRgb()
    const clazz = dark ? '.ud-admin-theme-dark' : ':root'
    const elem = document.createElement('style')
    elem.id = id
    elem.setAttribute('type', 'text/css')
    elem.innerHTML = `${clazz}{` + colors.map((c, i) => {
      return `--primary-${i + 1}:${c};`
    }).concat([
      `--primary-color:${color};`,
      `--primary-fade-8:rgba(${rgb.r},${rgb.g},${rgb.b},.08);`,
      `--primary-fade-20:rgba(${rgb.r},${rgb.g},${rgb.b},.2);`
    ]).join('') + '}'
    document.head.appendChild(elem)
  }
  if (dark) {
    document.body.classList.add('ud-admin-theme-dark')
  } else {
    document.body.classList.remove('ud-admin-theme-dark')
  }
}

/**
 * 倒计时
 * @param endTime 结束时间
 * @param serverTime 服务端当前时间
 * @param callback 回调
 * @returns {number} 定时器实例
 */
export function countdown(endTime, serverTime, callback) {
  const type = typeof serverTime === 'function'
  const end = new Date(endTime).getTime()
  const now = new Date((!serverTime || type) ? new Date().getTime() : serverTime).getTime()
  const count = end - now
  const time = [
    Math.floor(count / (1000 * 60 * 60 * 24)), // 天
    Math.floor(count / (1000 * 60 * 60)) % 24, // 时
    Math.floor(count / (1000 * 60)) % 60, // 分
    Math.floor(count / 1000) % 60 // 秒
  ]
  if (type) {
    callback = serverTime
  }
  const timer = setTimeout(() => {
    this.countdown(endTime, now + 1000, callback)
  }, 1000)
  callback && callback(count > 0 ? time : [0, 0, 0, 0], serverTime, timer)
  if (count <= 0) {
    clearTimeout(timer)
  }
  return timer
}

/**
 * 时间语义化
 * @param time 时间
 * @param onlyDate 超过30天是否仅返回日期
 * @returns {string}
 */
export function timeAgo(time, onlyDate) {
  if (!time) {
    return ''
  }
  if (typeof time === 'string') {
    time = time.replace(/-/g, '/')
  }
  const arr = [[], []]
  let stamp = new Date().getTime() - new Date(time).getTime()
  // 30天以上返回具体日期
  if (stamp > 1000 * 60 * 60 * 24 * 31) {
    stamp = new Date(time)
    arr[0][0] = this.digit(stamp.getFullYear(), 4)
    arr[0][1] = this.digit(stamp.getMonth() + 1)
    arr[0][2] = this.digit(stamp.getDate())
    // 是否输出时间
    if (!onlyDate) {
      arr[1][0] = this.digit(stamp.getHours())
      arr[1][1] = this.digit(stamp.getMinutes())
      arr[1][2] = this.digit(stamp.getSeconds())
    }
    return arr[0].join('-') + ' ' + arr[1].join(':')
  }
  if (stamp >= 1000 * 60 * 60 * 24) {
    return ((stamp / 1000 / 60 / 60 / 24) | 0) + '天前'
  } else if (stamp >= 1000 * 60 * 60) {
    return ((stamp / 1000 / 60 / 60) | 0) + '小时前'
  } else if (stamp >= 1000 * 60 * 3) {
    return ((stamp / 1000 / 60) | 0) + '分钟前'
  } else if (stamp < 0) {
    return '未来'
  } else {
    return '刚刚'
  }
}

/**
 * html转义
 * @param html
 * @returns {string}
 */
export function escape(html) {
  return String(html || '')
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;')
}

/**
 * 让浏览器全屏切换
 * @param el dom
 * @param fullscreen 是否全屏
 * @returns {boolean} 全屏状态
 */
export function toggleFullscreen(el, fullscreen) {
  if (!el) {
    el = document.documentElement
  }
  if (typeof fullscreen === 'undefined' || fullscreen === null) {
    fullscreen = !isFullscreen()
  }
  if (fullscreen) {
    const rfs = (
      el.requestFullScreen ||
      el.webkitRequestFullScreen ||
      el.mozRequestFullScreen ||
      el.msRequestFullScreen
    )
    if (rfs) {
      rfs.call(el)
    } else {
      throw new Error('您的浏览器不支持全屏模式')
    }
  } else {
    const cfs = (
      document.exitFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      document.msExitFullscreen
    )
    if (cfs) {
      cfs.call(document)
    }
  }
  return fullscreen
}

/**
 * 获取当前是否是全屏状态
 * @returns {boolean}
 */
export function isFullscreen() {
  return !!(
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  )
}

/**
 * 获取屏幕宽度
 * @returns {number}
 */
export function screenWidth() {
  return document.documentElement.clientWidth || document.body.clientWidth
}

/**
 * 获取屏幕高度
 * @returns {number}
 */
export function screenHeight() {
  return document.documentElement.clientHeight || document.body.clientHeight
}

/**
 * 获取html的纯文本
 * @param html
 * @returns {string}
 */
export function htmlToText(html) {
  return html.replace(/<[^>]+>/g, '')
}

/**
 * 获取设备信息
 * @param key 自定义的agent
 * @returns
 */
export function device(key) {
  const agent = navigator.userAgent.toLowerCase()
  const result = { os: null, ie: false }

  // 获取版本号
  function getVersion(label) {
    const exp = new RegExp(label + '/([^\\s\\_\\-]+)')
    return (agent.match(exp) || [])[1] || false
  }

  // 获取操作系统
  if (/windows/.test(agent)) {
    result.os = 'windows'
  } else if (/linux/.test(agent)) {
    result.os = 'linux'
  } else if (/iphone|ipod|ipad|ios/.test(agent)) {
    result.os = 'ios'
  } else if (/mac/.test(agent)) {
    result.os = 'mac'
  } else if (/android/.test(agent)) {
    result.os = 'android'
  }

  // 获取ie版本
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    result.ie = (agent.match(/msie\s(\d+)/) || [])[1] || '11'
  }

  // 获取微信版本
  result.weixin = getVersion('micromessenger')

  // 获取自定义的agent
  if (key && !result[key]) {
    result[key] = getVersion(key)
  }

  // 是否移动设备
  result.android = /android/.test(agent)
  result.ios = result.os === 'ios'
  result.mobile = result.android || result.ios
  return result
}

/**
 * 生成随机字符串
 * @param length 长度
 * @param radix 基数
 * @returns {string}
 */
export function uuid(length = 32, radix) {
  const num = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += num.charAt(Math.floor(Math.random() * (radix || num.length)))
  }
  return result
}

/**
 * 生成m到n的随机数
 * @param m 最小值, 包含
 * @param n 最大值, 不包含
 * @returns {number}
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n)
}

/**
 * 百度地图坐标转高德地图坐标
 * @param point 坐标
 * @returns {{lng: number, lat: number}}
 */
export function bd09ToGcj02(point) {
  const xPi = (3.14159265358979324 * 3000.0) / 180.0
  const x = point.lng - 0.0065
  const y = point.lat - 0.006
  const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * xPi)
  const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * xPi)
  return {
    lng: z * Math.cos(theta),
    lat: z * Math.sin(theta)
  }
}

/**
 * 高德地图坐标转百度地图坐标
 * @param point 坐标
 * @returns {{lng: number, lat: number}}
 */
export function gcj02ToBd09(point) {
  const xPi = (3.14159265358979324 * 3000.0) / 180.0
  const x = point.lng
  const y = point.lat
  const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * xPi)
  const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * xPi)
  return {
    lng: z * Math.cos(theta) + 0.0065,
    lat: z * Math.sin(theta) + 0.006
  }
}

/**
 * 深度克隆
 * @param obj
 * @return
 */
export function deepClone(obj) {
  let result
  const type = typeOf(obj)
  if (type === 'Object') {
    result = {}
  } else if (type === 'Array') {
    result = []
  } else {
    return obj
  }
  Object.keys(obj).forEach(key => {
    const copy = obj[key]
    const cType = this.typeOf(copy)
    if (cType === 'Object' || cType === 'Array') {
      result[key] = deepClone(copy)
    } else {
      result[key] = obj[key]
    }
  })
  return result
}

/**
 * 获取变量类型
 * @param obj
 * @returns {string}
 */
export function typeOf(obj) {
  if (obj === null) {
    return 'Null'
  }
  if (typeof obj === 'undefined') {
    return 'Undefined'
  }
  return Object.prototype.toString.call(obj).slice(8, -1)
}

/**
 * 播放音频
 * @param url 音频地址
 */
export function play(url) {
  return new Audio(url).play()
}

/**
 * 数字千分位
 * @param num
 * @returns {string}
 */
export function formatNumber(num) {
  return String(num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
}

/**
 * 数字前置补零
 * @param num 数字
 * @param length 位数
 * @returns {string}
 */
export function digit(num, length) {
  let str = ''
  num = String(num)
  length = length || 2
  for (let i = num.length; i < length; i++) {
    str += '0'
  }
  return num < Math.pow(10, length) ? str + (num | 0) : num
}

/**
 * 时间格式化
 * @param time 时间
 * @param format 格式
 * @returns {string}
 */
export function toDateString(time, format) {
  if (!time) {
    return ''
  }
  if (typeof time === 'string') {
    time = time.replace(/-/g, '/')
  } else if (typeof time === 'number' && String(time).length === 10) {
    time = time * 1000 // 10位时间戳处理
  }
  const date = new Date(time)
  const ymd = [
    digit(date.getFullYear(), 4),
    digit(date.getMonth() + 1),
    digit(date.getDate())
  ]
  const hms = [
    digit(date.getHours()),
    digit(date.getMinutes()),
    digit(date.getSeconds())
  ]
  return (format || 'yyyy-MM-dd HH:mm:ss')
    .replace(/yyyy/g, ymd[0])
    .replace(/MM/g, ymd[1])
    .replace(/dd/g, ymd[2])
    .replace(/HH/g, hms[0])
    .replace(/mm/g, hms[1])
    .replace(/ss/g, hms[2])
}

/**
 * base64转文件格式
 * @param dataurl base64
 * @param filename 文件名
 * @returns {string}
 */
export const base64toFile = (dataurl, filename) => {
  filename = filename || 'file'
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  // suffix是该文件的后缀
  const suffix = mime.split('/')[1]
  // atob 对经过 base-64 编码的字符串进行解码
  const bstr = window.atob(arr[1])
  // n 是解码后的长度
  let n = bstr.length
  // Uint8Array 数组类型表示一个 8 位无符号整型数组 初始值都是 数子0
  const u8arr = new Uint8Array(n)
  // charCodeAt() 方法可返回指定位置的字符的 Unicode 编码。这个返回值是 0 - 65535 之间的整数
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
  // 第三个参数是 要放到文件中的内容的 MIME 类型
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}
