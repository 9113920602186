<!-- 顶栏消息通知图标 -->
<template>
  <a-dropdown v-model:visible="visible" :trigger="['click']">
    <a-badge :count="allNum" class="ud-notice-trigger">
      <bell-outlined style="padding: 6px;"/>
    </a-badge>
    <template #overlay>
      <div class="ant-dropdown-menu ud-notice-pop">
        <div @click.stop="">
          <a-tabs v-model:active-key="active">
            <a-tab-pane key="notice" :tab="noticeTitle" force-render>
              <a-list item-layout="horizontal" :data-source="notice">
                <template #renderItem="{item}">
                  <a-list-item>
                    <a-list-item-meta :title="item.title" :description="item.time">
                      <template #avatar>
                        <a-avatar :style="{background: item.color}">
                          <template #icon>
                            <component :is="item.icon"/>
                          </template>
                        </a-avatar>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
              <div v-if="notice.length" class="ud-cell ud-notice-actions">
                <div class="ud-cell-content" @click="clear('notice')">清空通知</div>
                <a-divider type="vertical"/>
                <div class="ud-cell-content" @click="more('notice')">查看更多</div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="message" :tab="messageTitle" force-render>
              <a-list item-layout="horizontal" :data-source="message">
                <template #renderItem="{item}">
                  <a-list-item>
                    <a-list-item-meta :title="item.title">
                      <template #avatar>
                        <a-avatar :src="item.avatar"/>
                      </template>
                      <template #description>
                        <div>{{ item.content }}</div>
                        <div>{{ item.time }}</div>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
              <div v-if="message.length" class="ud-cell ud-notice-actions">
                <div class="ud-cell-content" @click="clear('message')">清空私信</div>
                <a-divider type="vertical"/>
                <div class="ud-cell-content" @click="more('message')">查看更多</div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="todo" :tab="todoTitle" force-render>
              <a-list item-layout="horizontal" :data-source="todo">
                <template #renderItem="{item}">
                  <a-list-item>
                    <a-list-item-meta :description="item.desc">
                      <template #title>
                        <div class="ud-cell">
                          <div class="ud-cell-content">{{ item.title }}</div>
                          <a-tag :color="['', 'red', 'blue'][item.state]">
                            {{ ['未开始', '即将到期', '进行中'][item.state] }}
                          </a-tag>
                        </div>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
              <div v-if="todo.length" class="ud-cell ud-notice-actions">
                <div class="ud-cell-content" @click="clear('todo')">清空待办</div>
                <a-divider type="vertical"/>
                <div class="ud-cell-content" @click="more('todo')">查看更多</div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </template>
  </a-dropdown>
</template>

<script>
import {
  BellOutlined,
  NotificationFilled,
  PushpinFilled,
  VideoCameraFilled,
  CarryOutFilled,
  BellFilled
} from '@ant-design/icons-vue'

export default {
  name: 'UdNotice',
  components: {
    BellOutlined,
    NotificationFilled,
    PushpinFilled,
    VideoCameraFilled,
    CarryOutFilled,
    BellFilled
  },
  data() {
    return {
      visible: false,
      active: 'notice',
      notice: [],
      message: [],
      todo: []
    }
  },
  computed: {
    // 通知标题
    noticeTitle() {
      return this.notice.length ? `通知(${this.notice.length})` : '通知'
    },
    // 私信标题
    messageTitle() {
      return this.message.length ? `私信(${this.message.length})` : '私信'
    },
    // 待办标题
    todoTitle() {
      return this.todo.length ? `待办(${this.todo.length})` : '待办'
    },
    // 所有消息数量
    allNum() {
      return this.notice.length + this.message.length + this.todo.length
    }
  },
  methods: {
    /* 清空消息 */
    clear(type) {
      if (type === 'notice') {
        this.notice = []
      } else if (type === 'message') {
        this.message = []
      } else if (type === 'todo') {
        this.todo = []
      }
    },
    /* 查看更多 */
    more(type) {
      this.visible = false
      if (this.$route.path !== '/user/message' || this.$route.query.type !== type) {
        this.$router.push({ path: '/user/message', query: { type: type } })
      }
    }
  }
}
</script>

<style>
.ud-notice-trigger.ant-badge {
  color: inherit;
}

.ud-notice-pop.ant-dropdown-menu {
  padding: 0;
  width: 336px;
  max-width: 100%;
  margin-top: 11px;
}

/* 内容 */
.ud-notice-pop .ant-tabs-nav-wrap {
  text-align: center;
}

.ud-notice-pop .ant-list-item {
  padding-left: 24px;
  padding-right: 24px;
  transition: background-color .3s;
  cursor: pointer;
}

.ud-notice-pop .ant-list-item:hover {
  background: hsla(0, 0%, 60%, .05);
}

.ud-notice-pop .ant-tag {
  margin: 0;
}

/* 操作按钮 */
.ud-notice-pop .ud-notice-actions {
  border-top: 1px solid hsla(0, 0%, 60%, .15);
}

.ud-notice-pop .ud-notice-actions > .ud-cell-content {
  line-height: 46px;
  text-align: center;
  transition: background-color .3s;
  cursor: pointer;
}

.ud-notice-pop .ud-notice-actions > .ud-cell-content:hover {
  background: hsla(0, 0%, 60%, .05);
}
</style>
