<!-- 框架布局 -->
<template>
  <router-view #default="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>
import { mapGetters } from 'vuex'
import setting from '@/config/setting'

export default {
  name: 'DatavLayout',
  components: {},
  computed: {
    // 主页标题, 移除国际化上面template中使用:home-title="setting.homeTitle"
    homeTitle() {
      return this.$t('layout.home')
    },
    ...mapGetters(['theme', 'user'])
  },
  data() {
    return {
      // 全局配置
      setting: setting,
      // 是否显示修改密码弹窗
      showPassword: false,
      // 是否显示主题设置抽屉
      showSetting: false,
      // 是否显示主体部分
      showContent: true,
      // 项目名
      projectName: process.env.VUE_APP_NAME
    }
  },
  created() {
    // 获取用户信息
    this.getUserInfo()
  },
  methods: {
    /* 获取当前用户信息 */
    getUserInfo() {
      if (setting.userUrl) {
        this.$http
          .get(setting.userUrl)
          .then((res) => {
            const result = setting.parseUser
              ? setting.parseUser(res.data)
              : res.data
            if (result.code === 0) {
              const user = result.data
              this.$store.dispatch('user/setUser', user)
              this.$store.dispatch('user/setRoles', user ? user.roles : null)
              this.$store.dispatch(
                'user/setAuthorities',
                user ? user.authorities : null
              )
            } else if (result.msg) {
              this.$message.error(result.msg)
            }
            // 在用户权限信息请求完成后再渲染主体部分, 以免权限控制指令不生效
            this.showContent = true
          })
          .catch((e) => {
            console.error(e)
            this.showContent = true
            this.$message.error(e.message)
          })
      }
    },
    /* 顶栏右侧点击 */
    onItemClick(key) {
      if (key === 'password') {
        this.showPassword = true
      } else if (key === 'setting') {
        this.showSetting = true
      }
    },
    /* logo点击事件 */
    onLogoClick(isHome) {
      if (!isHome) {
        this.$router.push('/')
      }
    },
    /* 更新collapse */
    updateCollapse(value) {
      this.$store.dispatch('theme/set', { key: 'collapse', value: value })
    },
    /* 更新屏幕尺寸 */
    updateScreen() {
      this.$store.dispatch('theme/updateScreen')
      const checkFullscreen = this.$refs.header.checkFullscreen
      checkFullscreen && checkFullscreen()
    },
    /* 切换主题风格 */
    changeStyle(value) {
      this.$store.dispatch('theme/set', value)
    },
    /* 切换主题色 */
    changeColor(value) {
      const hide = this.$message.loading({ content: '正在加载主题...' })
      this.$store
        .dispatch('theme/setColor', value)
        .then(() => {
          hide()
        })
        .catch((e) => {
          console.error(e)
          hide()
          this.$message.error('主题加载失败')
        })
    },
    changeDarkMode(value) {
      this.$store.dispatch('theme/setDarkMode', value)
    },
    changeWeakMode(value) {
      this.$store.dispatch('theme/setWeakMode', value)
    },
    setHomeComponents(components) {
      this.$store.dispatch('theme/setHomeComponents', components)
    },
    /* 菜单路由国际化对应的名称 */
    i18n(path, key /*, menu */) {
      // 参数三menu即原始菜单数据, 如果需要菜单标题多语言数据从接口返回可用此参数获取对应的多语言标题
      // 例如下面这样写, 接口的菜单数据为{path: '/system/user', titles: {zh: '用户管理', en: 'User'}}
      // return menu ? menu.titles[this.$i18n.locale] : null;
      const k = 'route.' + key + '._name'
      const title = this.$t(k)
      return title === k ? null : title
    },
    /* 切换语言 */
    changeLanguage(lang) {
      this.$i18n.locale = lang
      this.$refs.layout.changeLanguage()
      localStorage.setItem('i18n-lang', lang)
    }
  }
}
</script>
<style>
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
